<template>
    <div>
        <el-row>
            <el-form v-model="params" :inline="true" label-position="right" label-width="110px" size="mini">
                <el-form-item label="状态" prop="status">
                    <el-select v-model="params.status">
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="item in statusList" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="配置名称" prop="name">
                    <el-input v-model="params.name" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="doSearch">搜索</el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row>
            <el-col :span="18">
                <el-button type="primary" icon="el-icon-plus" size="mini" v-permission="'waiter:config:add'" @click="handleAdd">新增</el-button>
            </el-col>
            <el-col :span="6">

            </el-col>
        </el-row>
        <el-table :data="dataList" style="width: 100%" stripe fit max-height="640" v-loading="loading">
            <el-table-column align="center" label="配置名称" prop="name"></el-table-column>
            <el-table-column align="center" label="最大服务学员数" prop="studentMax"></el-table-column>
            <el-table-column align="center" label="日领取学员数" prop="studentReceive"></el-table-column>
            <el-table-column align="center" label="日查看学员数" prop="studentView"></el-table-column>
<!--            <el-table-column align="center" label="服务组" prop="crowdId">-->
<!--                <template v-slot="scope">-->
<!--                    <span v-for="item in crowdList" :key="item.crowdId"-->
<!--                          v-if="item.crowdId === scope.row.crowdId">{{ item.name }}</span>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column align="center" label="状态" prop="status">
                <template v-slot="scope">
                    <span v-for="item in statusList" v-if="scope.row.status === item.value">{{ item.label }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="创建人" prop="createBy"></el-table-column>
            <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
            <el-table-column align="center" label="操作" fixed="right">
                <template v-slot="scope">
                    <el-button type="text" size="mini" icon="el-icon-edit-outline" v-permission="'waiter:config:edit'" @click="handleUpdate(scope.row)">
                        修改
                    </el-button>
                    <el-button type="text" size="mini" icon="el-icon-video-pause" v-permission="'waiter:config:disable'" v-if="scope.row.status === 0"
                               @click="handleDisable(scope.row)">禁用
                    </el-button>
                    <el-button type="text" size="mini" icon="el-icon-video-play" v-permission="'waiter:config:enable'" v-if="scope.row.status === 9"
                               @click="handleEnable(scope.row)">启用
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                @size-change="findPage"
                @current-change="findPage"
                :current-page.sync="params.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size.sync="params.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>

        <el-dialog :title="dataFromAdd?'添加数据':'修改数据'" :visible.sync="dataFromSync" width="800px">
            <el-form :model="dataFrom" :rules="dataFromRules" ref="dataFrom" label-width="120px" size="medium">
                <el-form-item label="配置名称" prop="name">
                    <el-row>
                        <el-col :span="14">
                            <el-input v-model="dataFrom.name"></el-input>
                        </el-col>
                        <el-col :span="9" :push="1">
                            配置名称
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="最大服务学员数" prop="studentMax">
                    <el-row>
                        <el-col :span="14">
                            <el-input type="number" v-model="dataFrom.studentMax"></el-input>
                        </el-col>
                        <el-col :span="9" :push="1">
                            班主任账号最大领取学员数量
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="日领取学员数" prop="studentReceive">
                    <el-row>
                        <el-col :span="14">
                            <el-input type="number" v-model="dataFrom.studentReceive"></el-input>
                        </el-col>
                        <el-col :span="9" :push="1">
                            班主任账号每日领取取学员数量
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="日查看学员数" prop="studentView">
                    <el-row>
                        <el-col :span="14">
                            <el-input type="number" v-model="dataFrom.studentView"></el-input>
                        </el-col>
                        <el-col :span="9" :push="1">
                            班主任账号每日查看学员数量
                        </el-col>
                    </el-row>
                </el-form-item>
<!--                <el-form-item label="可见服务组" prop="crowdId">-->
<!--                    <el-row>-->
<!--                        <el-col :span="14">-->
<!--                            <el-select v-model="dataFrom.crowdId" style="width: 100%;">-->
<!--                                <el-option v-for="item in crowdList" :key="item.crowdId" :label="item.name"-->
<!--                                           :value="item.crowdId"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-col>-->
<!--                        <el-col :span="9" :push="1">-->
<!--                            归属的服务组-->
<!--                        </el-col>-->
<!--                    </el-row>-->
<!--                </el-form-item>-->
                <el-form-item label="领取按钮" prop="btnList">
                    <el-row>
                        <el-col :span="10">
                            <el-select v-model="btnId" style="width: 100%;">
                                <el-option v-for="item in receiveList" :key="item.btnId" :label="item.name"
                                           :value="item.btnId"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="4" style="text-align: center">
                            <el-button type="success" icon="el-icon-plus" @click="handleAddBtn"></el-button>
                        </el-col>
                        <el-col :span="9" :push="1">
                            添加可以查看的领取按钮
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item>
                    <el-row v-for="item in dataFrom.btnList" :key="item.btnId">
                        <el-col :span="7">
                            <span v-for="btn in receiveList" :key="btn.btnId"
                                  v-if="btn.btnId === item.btnId">{{ btn.name }}
                                <el-button :type="btn.btnType" size="mini">{{ btn.label }}</el-button>
                            </span>
                        </el-col>
                        <el-col :span="4">
                            <el-input type="number" v-model="item.size"></el-input>
                        </el-col>
                        <el-col :span="3" style="text-align: center">
                            <el-button type="info" icon="el-icon-delete" @click="handleDelBtn(item)"></el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="medium" @click="submitForm('dataFrom')">确 定</el-button>
                <el-button size="medium" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as configureApi from "@/api/waiter/waiterConfigure"
import * as crowdApi from "@/api/waiter/waiterServicesCrowd"
import * as receiveApi from "@/api/waiter/waiterReceiveBtn"

export default {
    name: "WaiterConfigure",
    data() {
        return {
            btnId: null,
            statusList: [{label: '正常', value: 0}, {label: '禁用', value: 9}],
            dataFrom: {
                btnList: [],
            },
            dataFromRules: {
                name: [{required: true, message: '请输入名称', trigger: 'blur'}],
                studentMax: [{required: true, message: '请设置最大服务学员数', trigger: 'blur'}],
                studentReceive: [{required: true, message: '请设置日领取学员数', trigger: 'blur'}],
                studentView: [{required: true, message: '请设置日查看学员数', trigger: 'blur'}],
                // crowdId: [{required: true, message: '请选择服务组', trigger: 'blur'}],
            },
            dataFromSync: false,
            dataFromAdd: true,
            params: {
                current: 1,
                size: 10,
                status: 0,
            },
            loading: false,
            dataList: [],
            total: 0,
            idList: [],
            crowdList: [],
            receiveList: [],
            receiveMap: {},
        }
    },
    mounted() {
        this.findPage()
        this.findCrowdList()
        this.findReceiveList()
    },
    methods: {
        handleDelBtn(btn) {
            this.dataFrom.btnList.splice(this.dataFrom.btnList.indexOf(btn), 1);
        },
        handleAddBtn() {
            if (this.btnId) {
                if (this.dataFrom.btnList && this.dataFrom.btnList.map(e => e.btnId).indexOf(this.btnId) > -1) {
                    this.$message.error("当前按钮已添加")
                    return
                }
                let receive = this.receiveMap[this.btnId]
                this.dataFrom.btnList.push({btnId: receive.btnId, size: receive.receiveSize})
            } else {
                this.$message.error("请选择添加的领取按钮")
            }
        },
        findReceiveList() {
            receiveApi.findList().then(res => {
                if (res.success) {
                    this.receiveList = res.data
                    for (let item of this.receiveList) {
                        this.receiveMap[item.btnId] = item
                    }
                }
            })
        },
        findCrowdList() {
            crowdApi.findList().then(res => {
                if (res.success) {
                    this.crowdList = res.data
                }
            })
        },
        /**
         * 取消
         */
        cancel() {
            this.dataFromSync = false
        },
        /**
         * 保存
         */
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    configureApi.saveData(this.dataFrom).then(res => {
                        if (res.success) {
                            this.$message({
                                type: "success",
                                message: '保存成功！'
                            });
                            this.dataFromSync = false
                            this.findPage()
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg
                            });
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        /**
         * 启用
         */
        handleEnable(row) {
            let ids = (row.configureId ? [row.configureId] : null) || this.idList
            if (ids) {
                configureApi.handleEnable(ids).then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: '保存成功！'
                        });
                        this.findPage()
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg
                        });
                    }
                })
            }
        },
        /**
         * 禁用
         */
        handleDisable(row) {
            let ids = (row.configureId ? [row.configureId] : null) || this.idList
            if (ids) {
                configureApi.handleDisable(ids).then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: '保存成功！'
                        });
                        this.findPage()
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg
                        });
                    }
                })
            }
        },
        /**
         * 修改
         * @param row
         */
        handleUpdate(row) {
            this.dataFromSync = true
            this.dataFromAdd = false
            configureApi.info(row.configureId).then(res => {
                if (res.success) {
                    this.dataFrom = res.data
                }
            })
            if (!this.dataFrom.btnList) {
                this.dataFrom.btnList = []
            }
        },
        /**
         * 添加
         */
        handleAdd() {
            this.dataFromSync = true
            this.dataFromAdd = true
            this.dataFrom = {
                studentMax: 10,
                studentView: 10,
                studentReceive: 10,
                btnList: [],
            }
        },
        /**
         * 重置
         */
        resetQuery() {
            this.params = {
                current: 1,
                size: 10,
                status: 0,
            }
        },
        /**
         * 点击搜索
         */
        doSearch() {
            this.params.current = 1
            this.findPage()
        },
        /**
         * 分页查询数据
         */
        findPage() {
            this.loading = true
            configureApi.findPage(this.params).then(res => {
                this.loading = false
                if (res.success) {
                    this.dataList = res.data.records
                    this.total = res.data.total
                }
            })
        },
    }
}
</script>

<style scoped>
.el-pagination {
    bottom: 10px;
    position: fixed;
}
</style>